/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
